.about-invester-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 40px 100px;
  background-color: #fffaed;
}

.about-invester-second-card {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  width: 100%;
}

.about-invester-second-single-card {
  width: 262px;
  height: 350px;
  /* border: 2px solid red; */
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.about-invester-second-single-card > img {
  width: 100%;
  height: 75%;
  object-fit: cover;
}

.about-invester-second-single-card > p {
  color: grey;
}

@media (max-width: 767px) {
  .about-invester-container {
    padding: 40px;
  }
  #new-inverts-first-card {
    width: 100%;
  }
  #new-inverts-first-card > h1 {
    font-size: 8vw !important;
  }
  .about-invester-second-card {
    align-items: center;
    gap: 1rem;
    justify-content: center;
  }
}
