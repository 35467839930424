* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  /* gap: 3rem; */
}

/* input */
.inputBox {
  position: relative;
  /* width: 350px; */
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  /* border: 2px solid blue; */
  /* margin-bottom: 15px; */
}

.inputBox input {
  width: 100%;
  padding: 10px 15px;
  border: 1px solid rgba(168, 119, 119, 0.25);
  outline: none;
  border-radius: 10px;
  font-size: 1.2rem;
  height: 45px;
  /* color: #fff; */
}

.inputBox span {
  position: absolute;
  left: 0;
  padding: 10px;
  pointer-events: none;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.25);
  /* text-transform: uppercase; */
  color: rgb(81, 81, 85);
  transition: 0.5s;
  top: 0px;
}

.inputBox input:focus {
  height: 45px;
}

.inputBox input:valid ~ span,
.inputBox input:focus ~ span {
  color: #ff6600;
  transform: translateX(0px) translateY(-10px);
  font-size: 0.65em;
}

.inputBox input:valid,
.inputBox input:focus {
  border: 2px solid #ff6600;
}

.inputBox select {
  width: 100%;
  padding: 10px 15px;
  border: 1px solid rgba(168, 119, 119, 0.25);
  outline: none;
  border-radius: 10px;
  font-size: 0.9rem;
  color: rgb(81, 81, 85);
  height: 45px;
  /* color: #fff; */
}

.inputBox span {
  position: absolute;
  left: 0;
  padding: 10px;
  pointer-events: none;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.25);
  /* text-transform: uppercase; */
  color: rgb(81, 81, 85);
  transition: 0.5s;
  top: 0px;
}

.inputBox textarea {
  width: 100%;
  padding: 10px 15px;
  border: 1px solid rgba(168, 119, 119, 0.25);
  outline: none;
  border-radius: 10px;
  font-size: 1.2rem;
  height: 45px;
  /* color: #fff; */
}

.inputBox span {
  position: absolute;
  left: 0;
  padding: 10px;
  pointer-events: none;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.25);
  /* text-transform: uppercase; */
  color: rgb(81, 81, 85);
  transition: 0.5s;
  top: 0px;
}

.inputBox textarea:focus {
  height: 45px;
}

.inputBox textarea:valid ~ span,
.inputBox textarea:focus ~ span {
  color: #ff6600;
  transform: translateX(0px) translateY(-10px);
  font-size: 0.65em;
}

.inputBox textarea:valid,
.inputBox textarea:focus {
  border: 2px solid #ff6600;
}

p {
  font-family: "Lato", sans-serif;

  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto" sans-serif;
}

.link-style {
  color: #ff6600;
  text-decoration: none;
  font-size: 14px;
}
