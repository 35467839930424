.about-what-we-focus {
  width: 100%;
  display: flex;
  align-items: flex-start;
  background-color: #fffaed;
  padding: 40px 100px;
  justify-content: space-around;
}

.about-what-we-focus > :nth-child(1) {
  width: 40%;
  /* border: 2px solid red; */
}

.about-what-we-right-side-main-card {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.about-what-we-right-inner-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.about-what-we-right-inner-card > div {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 35%;
  /* border: 2px solid red; */
}
.about-what-we-right-inner-card > div > p {
  font-weight: 600;
}

@media (max-width: 767px) {
  .about-what-we-focus {
    padding: 40px;
    flex-direction: column;
    gap: 2rem;
  }
  .about-what-we-focus > :nth-child(1) {
    width: 100%;
  }
  .about-what-we-focus > :nth-child(1) > h1 {
    font-size: 8vw;
  }
  .about-what-we-right-side-main-card {
    width: 100%;
  }
  .about-what-we-right-inner-card > div {
    width: 40%;
  }
}
