.contact-containe {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.contact-first-card {
  width: 100%;
  height: 500px;
  background-image: url("https://www.airvistara.com/content/dam/airvistara/global/english/common/image/Contact%20Us-26%20Apr.jpg");
  background-size: cover;
  margin-top: 40px;
  /* padding: 80px; */
  position: relative;
}

.contact-first-card > :nth-child(1) {
  width: 90%;
  position: absolute;
  bottom: 80px;
  left: 80px;
  z-index: 3;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.contact-first-card > :nth-child(1) > h1 {
  color: #ff6600;
  font-size: 58px;
}

.contact-first-card > :nth-child(1) > p {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #fff;
}

.contact-blur-contact {
  position: absolute;
  bottom: 50px;
  left: 0;
  width: 100vw !important;
  height: 60%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.466);
  /* border: 2px solid red; */
  filter: blur(50px);
}

@media (max-width: 768px) {
  .contact-first-card > :nth-child(1) {
    left: 0;
    padding: 10px;
  }

  .contact-first-card > :nth-child(1) > h1 {
    font-size: 10vw;
  }
}
