.slider-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  /* border: 2px solid #ccc; */
  border-radius: 10px;
  /* background-color: #f9f9f9; */
}

.slider-content {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 100%;
}

.slide {
  min-width: 100%;
  height: 350px;
  display: flex;
  /* align-items: center;
  justify-content: center; */
  font-size: 24px;
  /* background-color: #ddd; */
  /* text-align: center; */
  /* box-sizing: border-box; */
  gap: 2rem;
}

.slide > img {
  width: 50%;
}

.slider-inner-card {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 50%;
  height: 100%;
  /* align-items: center; */
  justify-content: center;
}

.slider-inner-card > h3 {
  font-size: 20px;
}

.slider-inner-card > p {
  font-size: 16px;
  font-weight: 500;
}

.slider-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  font-size: 24px;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
}

.slider-button.prev {
  left: 10px;
}

.slider-button.next {
  right: 10px;
}

.slider-button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

@media (max-width: 564px) {
  .slider-container {
    width: 95%;
  }
  .slide {
    gap: 1rem;
  }
}

@media (min-width: 565px) and (max-width: 768px) {
  .slider-container {
    width: 80%;
  }
}
