.footer-container {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  background-color: #1d1a12;
  padding: 40px 100px;
  color: #ffff;
}

.footer-first-card {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.footer-second-card {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  /* justify-content: space-between; */
  /* align-items: center; */
}

.footer-second-card > div {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.footer-third-card {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  /* align-items: flex-start; */
}

.footer-third-card > :nth-child(2) {
  border-bottom: 1px solid grey;
  padding-bottom: 10px;
}

.footer-third-card > div {
  padding: 20px 0px;
}
.footer-third-card > div > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid grey;
}

.footer-icons {
  display: flex;
  gap: 1rem;
}

.footer-stoies-card {
  display: flex;
  gap: 1rem;
}

.footer-stoies-card > img {
  width: 200px;
}

@media (max-width: 767px) {
  .footer-container {
    padding: 40px;
    flex-direction: column;
    gap: 2rem;
  }
}
