.about-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.about-first-card {
  width: 100%;
  height: 500px;
  background-image: url("https://cdnassets.hw.net/69/0b/d86856444918ac610a8950722d61/adobestock-567409180.jpg");
  background-size: cover;
  margin-top: 40px;
  /* padding: 80px; */
  position: relative;
}

.about-first-card > :nth-child(1) {
  width: 40%;
  position: absolute;
  bottom: 80px;
  left: 80px;
  z-index: 3;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.about-first-card > :nth-child(1) > h1 {
  color: #ff6600;
}

.about-first-card > :nth-child(1) > p {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #fff;
}

.about-blur-contact {
  position: absolute;
  bottom: 50px;
  left: 0;
  width: 100vw !important;
  height: 60%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.466);
  /* border: 2px solid red; */
  filter: blur(50px);
}

.about-second-card {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  padding: 50px 0px;
}

.about-second-card > p {
  font-weight: 600;
  font-style: 18px;
  text-align: center;
}

.about-second-time-line-card {
  width: 50%;
  /* border: 2px solid red; */
  display: flex;
}

.about-tabs-number-inner-card {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 23%;
}
.about-tabs-number-inner-cards {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 15%;
}

.about-tabs-number-single-card {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-tabs-number-single-card > p {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.about-tabs-number-inner-cards > span {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.about-tabs-number-single-card > span {
  border-bottom: 2px dashed black;
  width: 80%;
}

.about-tabs-number-inner-card > p {
  font-family: "Lato", sans-serif;
  font-weight: 600;
}

.about-tabs-number-inner-cards > p {
  font-weight: 600;
}
.about-second-time-line-image-card {
  width: 50%;
  border: 2px solid red;
  height: 350px;
}

.about-second-time-line-slider-card {
  width: 100%;
  height: 90%;
}

.about-second-time-line-slider-card > div {
  width: 100%;
  display: flex;
  flex-direction: column; /* Ensures content stacks vertically */
  align-items: center; /* Centers content horizontally */
  justify-content: center; /* Centers content vertically */
  gap: 1rem; /* Adjusted gap to be less aggressive */
  border: 2px solid red;
  height: 100%;
  text-align: center; /* Ensures text is centered */
  box-sizing: border-box; /* Ensures padding and border are included in width and height */
}

.about-second-time-line-slider-card > div > img {
  width: 100%;
  object-fit: fill;
}

/*  */

@media (max-width: 767px) {
  .about-first-card > :nth-child(1) {
    width: 100%;
    left: 10px;
  }
  .about-second-time-line-card {
    width: 80%;
  }
}
