.our-projects-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 20px 100px;
}

.carousel-container {
  position: relative;
  width: 100%;
  margin: 0 auto;
}

.carousel {
  position: relative;
  overflow: hidden;
}

.carousel-inner {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 100%;
  /* border: 2px solid red; */
}

.carousel-slide {
  /* border: 1px solid; */

  width: 33.33%;
  display: flex;
  flex-direction: column;
  margin: 0 19px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  margin-bottom: 20px;
}

.carousel-slide > img {
  width: 345px;
  height: 170px;
  /* border-radius: 8px; */
}
.carousel-content {
  padding: 14px;
  margin: 0;
  /* border: 1px solid red; */

  & h3 {
    padding: 0;
    margin: 0;
  }

  & p {
    padding: 0;
    margin: 0;
  }

  &:hover {
    background-color: #ff6600;
    cursor: pointer;
    color: white;
  }
}

.carousel-button {
  position: absolute;
  top: 40%;
  height: 50px;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-50%);
  background-color: #fff !important;
  color: rgb(0, 0, 0);
  border: none;
  background: none;
  border: 1px solid red;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
}

.carousel-button.left {
  left: -15px;
}

.carousel-button.right {
  right: -15px;
}

.carousel-button:hover {
  background-color: rgba(183, 177, 177, 0.8) !important;
}

.carousel-dots {
  text-align: center;
  margin-top: 15px;
}

.dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  background-color: #bbb;
  border-radius: 50%;
  cursor: pointer;
}

.dot.active {
  background-color: #333;
}

@media (max-width: 767px) {
  .our-projects-container {
    padding: 40px;
    display: none;
  }
  .carousel-slide {
    width: 100%;
  }
}
