.career-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.career-first-card {
  width: 100%;
  height: 500px;
  background-image: url("https://media.foundit.in/career-advice/wp-content/uploads/2022/03/career-in-physical-education.jpg");
  background-size: cover;
  margin-top: 40px;
  /* padding: 80px; */
  position: relative;
}

.career-first-card > :nth-child(1) {
  width: 40%;
  position: absolute;
  bottom: 80px;
  left: 80px;
  z-index: 3;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.career-first-card > :nth-child(1) > h1 {
  color: #ff6600;
  font-size: 58px;
}

.career-first-card > :nth-child(1) > p {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #fff;
}

.career-blur-contact {
  position: absolute;
  bottom: 50px;
  left: 0;
  width: 100vw !important;
  height: 60%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.466);
  /* border: 2px solid red; */
  filter: blur(50px);
}

.career-second-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  padding: 40px 100px;
}

.career-second-card > p {
  width: 60%;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 25px;
  text-align: center;
}

@media (max-width: 768px) {
  .career-first-card > :nth-child(1) {
    width: 100%;
    left: 10px;
  }

  .career-second-card {
    padding: 40px;
  }
  .career-second-card > p {
    width: 100%;
  }
}
