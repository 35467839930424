.bank-partneer-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 40px 100px;
  background-color: #fff6f4;
  width: 100%;
}

.bank-referral-images-card {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  flex-wrap: wrap;
  gap: 2rem;
}

/* .bank-referral-images-card > img {
  width: 20%;
} */

@media (max-width: 767px) {
  .bank-partneer-container {
    padding: 40px;
  }
  .referral-program-icons-card > h1 {
    font-size: 6vw;
  }
  .bank-partneer-container > p {
    text-align: center;
  }
  .bank-referral-images-card {
    align-items: center;
  }
}
