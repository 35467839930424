.home-main-card {
  width: 100%;
  height: 480px;
  /* border: 2px solid red; */
  position: relative;
}

.home-main-card > img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.hero-black-card {
  width: 35%;
  height: 230px;
  background-color: rgba(0, 0, 0, 0.767);
  position: absolute;
  top: 15%;
  left: 0;
  /* transform: translateY(-50%); */
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  color: #fff;
  padding: 20px 40px;
}

.hero-black-card > h2 {
  font-size: 32px;
  margin: 0;
}

.hero-black-card > :nth-child(2) {
  color: #ff6600;
}

.hero-black-card > span {
  font-size: 16px;
  font-weight: 400;
}

.hero-black-inner-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hero-black-inner-card > div {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.hero-black-inner-card > div > div {
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
}
.hero-black-inner-card > div > div > h4 {
  color: #ff6600;
}

.hero-black-inner-card > div > div > span {
  font-size: 12px;
}

.hero-contact-card {
  width: 350px;
  height: 105%;
  position: absolute;
  right: 20px;
  top: 20px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
  align-items: center;
  gap: 1.5rem;
  /* border: 2px solid red; */
}

.hero-contact-card > span {
  font-size: 16px;
  font-family: "Lato", sans-serif;
  font-weight: 500;
}

.hero-form-card {
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
  width: 100%;
}
.hero-form-card > div {
  width: 100%;
}

.form-btn-card {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #ff6600;
  height: 40px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
}

.form-btn-card > button {
  border: none;
  outline: none;
  color: #fff;
  font-size: 18px;
  background-color: transparent;
}

.hero-form-card > span {
  font-size: 12px;
  margin-top: -10px;
  color: grey;
}

.hero-form-card > span > span {
  color: #ff6600;
  font-size: 13px;
  cursor: pointer;
}

.hero-blog-related-post {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 170px;
  width: 60%;
  /* border: 2px solid red; */
  padding: 10px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hero-blog-first-card {
  display: flex;
  gap: 1rem;
  align-items: center;
  /* border: 2px solid yellow; */
  width: 50%;
}

.hero-blog-first-card > img {
  width: 36%;
}

.hero-blog-first-card > div {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  width: 60%;
  align-items: center;
  color: #fff;
}

.hero-blog-first-card > div > :nth-child(2) {
  /* background-color: #ff6600; */
  padding: 0px 16px;
  border: 2px solid silver;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 0.5rem;
  background-image: linear-gradient(to right, #f11d23, #f05825);
}

.hero-blog-first-card > div > :nth-child(2) > span {
  font-size: 20px;
  font-weight: bold;
}

.hero-blog-first-card > div > :nth-child(3) {
  background-color: #fff;
  color: #000;
  border-radius: 5px;
  font-size: 12px;
  padding: 2px 10px;
}

.hero-blog-first-card > div > :nth-child(4) {
  font-size: 10px;
}

@media (max-width: 768px) {
  .home-main-card {
    height: fit-content;
  }
  .hero-black-card {
    width: 95%;
    height: fit-content;
    padding: 15px;
    top: 40px;
    left: 0;
  }
  .hero-black-card > h2 {
    font-size: 4vw;
    margin: 0;
  }

  .hero-black-card > :nth-child(2) {
    color: #ff6600;
  }

  .hero-black-card > span {
    font-size: 10px;
    font-weight: 400;
  }
  .hero-black-inner-card > div > :nth-child(1) {
    font-size: 12px !important;
  }
  .hero-contact-card {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: fit-content;
  }
  .hero-blog-related-post {
    display: none;
  }
}
