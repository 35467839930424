.contact-form-main-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 80px 100px;
}

.contact-form-main-inner-card {
  width: 65%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.contact-form-main-inner-single-card {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 2px solid red; */
  gap: 1rem;
}

.contact-form-main-inner-single-card > div {
  width: 48%;
}

.contact-form-main-inner-single-div {
  width: 100% !important;
  height: 70px;
  /* border: 2px solid red; */
}

.contact-form-main-inner-single-div > textarea {
  height: 100%;
}

.contact-form-btn-card-mian {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.contact-form-btn-card-mian > :nth-child(1) {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.contact-form-btn-card-mian > :nth-child(1) > p > span {
  color: #ff6600;
  cursor: pointer;
}

.contact-btn-card-kjhgfc {
  width: 40%;
  background-color: #f15a29;
  padding: 10px 10px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  gap: 2rem;
  justify-content: center;
  /* border-radius: 10px; */
  cursor: pointer;
  clip-path: polygon(0 0, 100% 0, 100% 70%, 95% 100%, 0 100%);
}

.contact-btn-card-kjhgfc > button {
  font-size: 20px;
  color: #fff;

  background-color: transparent;
  border: none;
  outline: none;
}

@media (max-width: 768px) {
  .contact-form-main-container {
    padding: 40px;
  }
  .contact-form-main-inner-card {
    width: 100%;
  }
  .contact-form-main-inner-single-card {
    flex-direction: column;
  }
  .contact-form-main-inner-single-card > div {
    width: 100%;
  }
  .contact-form-btn-card-mian {
    flex-direction: column;
    gap: 1rem;
  }
  .contact-btn-card-kjhgfc {
    width: 80%;
  }
}
