.career-equal-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 40px 100px;
}

.career-equal-container > h1 {
  font-size: 48px;
}

.career-equal-container > p {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 25px;
}

.career-equal-container > p > span {
  color: #ff6600;
  cursor: pointer;
}

@media (max-width: 767px) {
  .career-equal-container {
    padding: 40px;
  }

  .career-equal-container > h1 {
    font-size: 8vw;
    text-align: center;
  }

  .career-equal-container > p {
    text-align: justify;
  }
}
