.how-its-word-containe {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 20px 100px;
  align-items: center;
  justify-content: center;
  background-color: #fff6f4;
}

.how-its-work-first-card {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.how-its-work-first-card > h1 {
  font-size: 38px;
  font-family: "Roboto" sans-serif;
}

.how-its-word-containe > p {
  font-family: "Lato", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.how-its-word-tabs-card {
  width: 86%;
  margin-top: 20px;
  display: flex;
  /* justify-content: space-between; */
  position: relative;
  /* border: 2px solid red; */
}

.how-its-work-tabs-number-inner-card {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 20%;
}
.how-its-work-tabs-number-inner-cards {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 7%;
}

.how-its-work-tabs-number-single-card {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.how-its-work-tabs-number-single-card > p {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.how-its-work-tabs-number-inner-cards > span {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.how-its-work-tabs-number-single-card > span {
  border-bottom: 2px dashed black;
  width: 80%;
}

.how-its-work-tabs-number-inner-card > p {
  font-family: "Lato", sans-serif;
  font-weight: 600;
}

.how-its-work-tab-image {
  width: 85%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.how-its-work-tab-image > img {
  width: 50%;
  height: 300px;
  object-fit: cover;
}

.how-its-work-tabs-text-card {
  width: 70%;
  background-color: #fff;
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: 2px solid #fdebe6;
}

.how-its-work-tabs-text-card > p {
  margin-left: 20px;
  line-height: 25px;
  letter-spacing: 0.6px;
}

@media (max-width: 767px) {
  .how-its-word-containe {
    padding: 40px;
  }
  .how-its-work-first-card > h1 {
    font-size: 7vw;
  }
  .how-its-word-containe > p {
    text-align: center;
  }
  .how-its-word-tabs-card {
    width: 100%;
  }
  .how-its-work-tabs-number-inner-card > p {
    font-size: 1.4vw;
  }
  .how-its-work-tabs-number-inner-cards > p {
    font-size: 1.4vw;
  }
  .how-its-work-tab-image {
    width: 100%;
    margin-top: 0px;
  }

  .how-its-work-tab-image > img {
    width: 90%;
  }
  .how-its-work-tabs-text-card {
    width: 100%;
  }
}
