* {
  margin: 0;
  padding: 0;
}

header {
  margin: 0;
  padding: 0;
  width: 100%;
  box-shadow: 0px 2px 9px 5px #0000001f;
}

.logo {
  width: 150px;
}
.img-logo {
  width: 60px;
  margin-top: 5px;
}

.header-container {
  padding: 0 4rem;
  display: flex;
  /* align-items: center; */
  padding: 0 100px;
  justify-content: center;
  width: 100%;
}

.header-container ul li {
  list-style: none;

  & a {
    text-decoration: none;
    &:hover {
      color: rgb(240, 90, 41);
    }
  }
}

.nav-ul {
  display: flex;
  gap: 2rem;
  align-items: center;
}
.nav-ul-mobile {
  display: none;
}
.nav-ul-li {
  color: grey;
}
.nav-ul > li .active {
  color: #ff6600;
}

.link-with-tag {
  position: relative;

  & span {
    font-size: 0.8rem;
    font-weight: 600;
    color: rgb(240, 90, 41);
    position: absolute;
    right: -1rem;
    bottom: 0.7rem;
  }
}

.dropbtn {
  font-size: 16px;
  color: rgb(240, 90, 41);
  font-weight: bold;
  height: 100%;
  padding: 1.6rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: none;
  background-color: rgb(250, 216, 205);
  border: none;
  cursor: pointer;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
/* .dropdown-content a:hover {background-color: #f1f1f1} */

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  color: rgb(240, 90, 41);
}

.header-btn {
  width: 200px;
  height: 40px;
  background-color: #ff6600;
  border: none;

  outline: none;
  cursor: pointer;

  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}

.header-contact-number-card {
  display: flex;
  gap: 1rem;
  border-left: 1px solid grey;
  padding-left: 20px;
}

.header-contact-number-card > :nth-child(1) {
  width: 40px;
  height: 40px;
  background-color: #007aff;
  border-radius: 50%;
  /* padding: 20px; */
  display: flex;
  justify-content: center;

  align-items: center;
}

.header-mobile-card {
  display: none;
}

@media (max-width: 767px) {
  .header-container {
    padding: 15px 30px;
    justify-content: space-between;
    align-items: center;
  }
  .nav-ul {
    display: none;
  }
  .nav-ul-mobile {
    position: absolute;
    top: 90px;
    left: 0;
    width: 100%;
    height: calc(100vh - 90px);
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    gap: 2rem;
    z-index: 999999;
  }
  .nav-ul-mobile > li {
    color: grey;
  }
  .header-mobile-card {
    display: block;
  }
}
